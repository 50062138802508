import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"

// Components
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import shuffle from '../helpers/shuffle'

// Helper
// Transitions
import Transition from '../components/transition/transition'
import Projects from '../components/pages/index/index__projects'

class CategoryPage extends React.PureComponent{
  constructor(props){
    super(props)
    this.state = {
      // posts: props.data.wpcontent.posts.nodes
      posts: shuffle(this.props.data.wpcontent.posts.nodes)
    }
  }



  render(){


    const filter = this.props.location.pathname.replace(/\/+/ig, '')
    console.log('filter', filter)

    return(
      <Layout className="page__category" >
        <SEO title="Home" />
        
        <Transition >
          <main>
            <Projects posts={this.state.posts} filter={filter} /> 
          </main>
        </Transition>
        
      </Layout>
    )
  }
}

export default CategoryPage

export const categoryQuery = graphql`
query categoryPost($slug: String!) {
  wpcontent{
    posts( first: 200, where: { categoryName: $slug } ) {
      nodes {
        title
        slug
        categories{
          nodes{
            slug
          }
        }
        postfields{
          type
          year
          titleExtended
          images {
            id
            mediaItemUrl
            mediaDetails {
              sizes {
                sourceUrl
                height
                width
              }
              height
              width
            }
          }
        }
      }
    }
  }
}
`
